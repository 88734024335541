<template>
  <div>
    <b-overlay :show="loading" rounded="sm">
      <div v-if="dati">
        <div class="row">
          <div class="col-6 pl-0">
            <h6
              class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey mt-2"
            >
              Utenze
            </h6>
            <p class="text-left text-medium-grey fs-5">
              Utenze registrate in piattaforma.
            </p>

            <div
              v-if="dati.utenti"
              class="justify-content-between align-items-center"
              key="cittadini"
            >
              <div class="col-12 text-left">
                <strong>Cittadini: {{ dati.utenti.cittadini }}</strong>
              </div>
              <div class="col-12 text-left">
                <strong
                  >Cittadini con questionari:
                  {{ dati.utenti.cittadini_questionari }}</strong
                >
              </div>
              <div class="col-12 text-left">
                <strong>Operatori: {{ dati.utenti.operatori }}</strong>
              </div>
              <div class="col-12 text-left">
                <strong>Totale: {{ dati.utenti.total }}</strong>
              </div>
            </div>
            <b-spinner v-else></b-spinner>
          </div>
          <div class="col-6 pl-0">
            <b-overlay :show="loading" rounded="sm">
              <template  v-if="dati.otp">
              <!-- <h6
                class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey mt-2"
              >
                Aziende
              </h6>
              <p class="text-left text-medium-grey fs-5">
                Aziende registrate in piattaforma suddivise per settore.
              </p>
              <template v-if="dati.aziende">
                <div
                  class="d-flex justify-content-between align-items-center"
                  :key="ind"
                  v-for="(azienda, ind) in dati.aziende"
                >
                  <div class="col-12 text-left">
                    <strong>{{ azienda.tipo }}: {{ azienda.totale }}</strong>
                  </div>
                </div>
              </template>
              <b-spinner v-else></b-spinner> -->
                <h6
                  class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey mt-2"
                >
                  SMS & OTP
                </h6>
                <p class="text-left text-medium-grey fs-5">
                  Sms inviati in piattaforma per inoltro OTP e per recupero
                  credenziali di accesso.
                </p>
                <template v-if="dati.otp">
                  <p class="text-left mb-0 pl-3">
                    Totale OTP inviati ad oggi:
                    <strong>{{ dati.otp.totale }} </strong> di cui
                    <strong>{{ dati.otp.mese_corrente }} </strong>
                    inviati questo mese.
                  </p>
                </template>
                <template v-if="dati.sms">
                  <p class="text-left mb-0 pl-3">
                    Totale SMS di recupero credenziali inviati ad oggi:
                    <strong>{{ dati.sms.totale }} </strong> di cui
                    <strong>{{ dati.sms.mese_corrente }} </strong>
                    inviati questo mese.
                  </p>
                </template>
              </template>
              <b-spinner v-else></b-spinner>
            </b-overlay>
          </div>
        </div>
        <h6
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey mt-4 pt-2"
        >
          Farmacie & Questionari
        </h6>
        <p class="text-left text-medium-grey fs-5">
          Dati relativi alle farmacie ed alle attività di sperimentazione in
          piattaforma; utilizza il filtro "Regione" per visualizzare i dati di
          una specifica regione.
        </p>

        <b-row>
          <b-form-group
            label="Regione"
            label-for="regione"
            class="text-left pl-3"
          >
            <b-form-select
              class="my-1 shadow_6 text-capitalize"
              :options="regioni"
              name="regione"
              v-model="filters.regione"
              @change="fetchSperimentazioneData"
            >
              <b-form-select-option :value="'tutte'"
                >Tutte</b-form-select-option
              >
              <b-form-select-option :value="undefined"
                >Seleziona</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-row>
        <template v-if="dati.farmacie && dati.questionari">
          <div class="row mt-3 pl-3">
            <div class="col-6 pl-0">
              <h6 class="text-primary fw-bolder text-left pb-1">Farmacie</h6>
              <div
                class="justify-content-between align-items-center"
                key="farmacie"
              >
                <div class="col-12 text-left">
                  <strong>Farmacie: {{ dati.farmacie.totali }}</strong>
                </div>
                <div class="col-12 text-left">
                  <strong
                    >Farmacie con contratto attivo:
                    {{ dati.farmacie.contratto_attivo }}</strong
                  >
                </div>
                <div class="col-12 text-left">
                  <strong
                    >Farmacie con contratto di sperimentazione:
                    {{ dati.farmacie.sperimentazione }}</strong
                  >
                </div>
                <div class="col-12 text-left">
                  <strong
                    >Farmacie che svolgono attività:
                    {{ dati.farmacie.attive }}</strong
                  >
                </div>
                <div class="col-12 text-left">
                  <strong
                    >Farmacie che non svolgono attività:
                    {{ dati.farmacie.non_attive }}</strong
                  >
                </div>
              </div>
            </div>
            <div class="col-6 pl-0">
              <h6 class="text-primary fw-bolder text-left pb-1">Questionari</h6>
              <!-- <p class="text-left text-medium-grey fs-5">
              Questionari somministrati in piattaforma.
            </p> -->
              <div
                class="justify-content-between align-items-center"
                key="questionari"
              >
                <div class="col-12 text-left">
                  <strong
                    >Questionari somministrati:
                    {{ dati.questionari.totali }}</strong
                  >
                </div>
              </div>
            </div>
          </div>
        </template>
        <b-spinner v-else></b-spinner>

        <!-- <h6
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey mt-4 pt-2"
        >
          SMS & OTP
        </h6>
        <p class="text-left text-medium-grey fs-5">
          Sms inviati in piattaforma per inoltro OTP e per recupero credenziali
          di accesso.
        </p>
        <template v-if="dati.otp">
          <p class="text-left mb-0 pl-3">
            Totale OTP inviati ad oggi:
            <strong>{{ dati.otp.totale }} </strong> di cui
            <strong>{{ dati.otp.mese_corrente }} </strong>
            inviati questo mese.
          </p>
        </template>
        <template v-if="dati.sms">
          <p class="text-left mb-0 pl-3">
            Totale SMS di recupero credenziali inviati ad oggi:
            <strong>{{ dati.sms.totale }} </strong> di cui
            <strong>{{ dati.sms.mese_corrente }} </strong>
            inviati questo mese.
          </p>
        </template> -->
        <h6
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey mt-4 pt-2"
        >
          Utilizzo
        </h6>
        <p class="text-left text-medium-grey fs-5">
          Dati utilizzo piattaforma.
        </p>
        <!-- <p class="text-left text-font-grey fs-5">
        Ordini in piattaforma suddivisi per tipologia:
      </p> -->

        <template v-if="dati.ordini && dati.servizi">
          <div
            class="d-flex justify-content-between align-items-center"
            :key="'d' + ind"
            v-for="(ordine, ind) in dati.ordini"
          >
            <div class="col-3 text-left">
              <strong>{{ ordine.tipo }}: </strong>
            </div>
            <div class="col-9">
              <b-progress
                :value="ordine.totale"
                :max="dati.ordini[dati.ordini.length - 1].totale"
                :variant="ind == 3 ? 'primary' : colors[ind]"
                show-value
              ></b-progress>
            </div>
          </div>
        </template>
        <b-spinner v-else></b-spinner>
      </div>
      <b-spinner v-else></b-spinner>
    </b-overlay>
  </div>
</template>
<script>
import { adminService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "DatiApp",
  data() {
    return {
      dati: null,
      colors: ["info", "prim-grad-1", "prim-grad-2", "success"],
      filters: {
        regione: undefined,
        utenze: true,
        // aziende: false,
        sperimentazione: true,
        utilizzo: true,
        otp: false,
      },
      loading: true,
      loadingOTP: true,
    };
  },
  methods: {
    fetchData() {
      const self = this;
      adminService
        .appData(this.filters)
        .then(function (resp) {
          self.dati = resp.data.data;
          self.dati.utenti = resp.data.data.utenti;
          //self.dati.otp = resp.data.data.otp;
          //self.dati.sms = resp.data.data.sms;
          self.loading = false;
          self.getDatiOTP();
        })
        .catch(function (err) {
          console.log(err);
          self.loading = false;
        });
    },
    fetchSperimentazioneData() {
      this.dati.farmacie = null;
      this.dati.questionari = null;
      this.filters.sperimentazione = true;
      this.filters.utenze = null;
      this.filters.utilizzo = null;
      this.filters.otp = null;
      const self = this;
      adminService
        .appData(this.filters)
        .then(function (resp) {
          self.dati.farmacie = resp.data.data.farmacie;
          self.dati.questionari = resp.data.data.questionari;
          self.loading = false;
        })
        .catch(function (err) {
          console.log(err);
          self.loading = false;
        });
    },
    getDatiOTP() {
      this.filters.sperimentazione = null;
      this.filters.utenze = null;
      this.filters.utilizzo = null;
      this.filters.otp = true;
      const self = this;
      adminService
        .appData(this.filters)
        .then(function (resp) {
          //self.dati.servizi = resp.data.data.servizi;
          //self.dati.ordini = resp.data.data.ordini;
          self.dati.otp = resp.data.data.otp;
          self.dati.sms = resp.data.data.sms;
          self.loadingOTP = false;
        })
        .catch(function (err) {
          console.log(err);
          self.loadingOTP = false;
        });
    },
    ...mapActions("utility", ["getRegioni"]),
  },
  created() {
    this.fetchData();
    this.getRegioni();
  },
  mounted() {
    // this.getDatiUtilizzo();
  },
  computed: {
    ...mapState("utility", ["regioni"]),
  },
};
</script>
